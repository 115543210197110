<template>
    <div>
        <table class="layui-table" lay-size="sm" style="width: 1200px">
            <thead>
            <tr>
                <th>实验条件</th>
                <th>默认值</th>
                <th>排序</th>
                <th>操作</th>
            </tr>
            </thead>
            <tbody>

            <tr v-for="( item,key) in tableData" :key="key">
                <td>
                    <a-input class="w200 noborder" v-model="item.condition"/>
                </td>
                <td>
                    <a-input class="w200 noborder" v-model="item.default_value"/>
                </td>
                <td>
                    <a-input class="w200 noborder" v-model="item.sort"/>
                </td>
                <td class="text-l">
                    <a-space>
                        <a-button type="primary" @click="Editclick(item)">保存</a-button>
                        <a-button type="danger" @click="Delclick(item)">删除</a-button>
                    </a-space>
                </td>
            </tr>

            <tr>
                <td>
                    <a-input class="w200 " v-model="creat.condition"/>
                </td>
                <td>
                    <a-input class="w200 " v-model="creat.default_value"/>
                </td>
                <td>
                    <a-input class="w200 " v-model="creat.sort"/>
                </td>
                <td class="text-l">
                    <a-button type="primary" @click="addclick()">增加</a-button>
                </td>
            </tr>

            </tbody>
        </table>
    </div>

</template>

<script>
    export default {
        name: "shiyanhuanjing",
        data() {
            return {
                tableData: [],
                creat: {
                    condition: '',
                    default_value: '',
                    sort: '',
                }
            }
        },
        mounted() {
            this.Get_ex_environ()
        },
        methods: {
            Get_ex_environ() {
                this.$sa0.post({
                    url: this.$api('Get_ex_environ'),
                    data: {},
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.tableData = response.data.result.list
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },
            addclick() {
                if (this.creat.condition.length === 0 || this.creat.default_value.length === 0 || this.creat.sort.length === 0) {
                    layer.msg("请输入完整的实验条件")
                    return
                }
                this.$sa0.post({
                    url: this.$api('Create_ex_environ'),
                    data: {
                        condition: this.creat.condition,
                        default_value: this.creat.default_value,
                        sort: this.creat.sort,
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            layer.msg(response.message)
                            this.Get_ex_environ()
                            this.creat = {
                                condition: '',
                                default_value: '',
                                sort: '',
                            }
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },
            Editclick(item) {
                this.$sa0.post({
                    url: this.$api('Edit_ex_environ'),
                    data: {
                        condition: item.condition,
                        default_value: item.default_value,
                        sort: item.sort,
                        ex_environ_id: item.id
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            layer.msg(response.message)
                            this.Get_ex_environ()
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },

            Delclick(item) {
                this.$sa0.post({
                    url: this.$api('Del_ex_environ'),
                    data: {
                        ex_environ_id: item.id
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            layer.msg(response.message)
                            this.Get_ex_environ()
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            }
        }
    }

</script>

<style scoped>
    .noborder{ border: none; text-align: center}

    .w200{ width: 200px}

    .text-l{ text-align: left}

</style>
